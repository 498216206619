<template>
    <v-container fill-height>
        <v-row>
            <v-col cols="10" class="mx-auto">
                <v-card elevation="1">
                    <div class="pa-5">
                        <div class="d-flex justify-space-between">
                            <div class="d-flex align-center">    
                                <v-icon>mdi-map-marker-plus</v-icon>
                                <v-toolbar-title class="ml-5">Location</v-toolbar-title>
                            </div>
                            <v-dialog v-model="dialog" persistent max-width="800px">
                                <template v-slot:activator="{ on }">
                                    <v-btn color="green lighten-2" v-on="on" outlined="">
                                        <v-icon left>mdi-plus</v-icon> 
                                        Add
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-title>
                                        <span class="text-h5">Location</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-form ref="form">     
                                                <v-row>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                        v-model="locationData.location"
                                                        :rules="locationRules"
                                                        label="Location*"
                                                        required
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                        v-model="locationData.updatedBy"
                                                        :rules="updateByRules"
                                                        label="Updated By*"
                                                        type="string"
                                                        required
                                                        ></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12">
                                                        <v-text-field
                                                        v-model="locationData.remarks"
                                                        label="Remarks(Optional)"
                                                        type="string"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>
                                            </v-form>
                                            <small>*indicates required field</small>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        type="submit"
                                        @click="saveLocation"
                                    >
                                        Save
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="resetForm"
                                    >
                                        Reset
                                    </v-btn>
                                    <v-btn
                                        color="blue darken-1"
                                        text
                                        @click="dialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                        <v-divider class="mt-4"></v-divider>
                        <br>
                        <v-data-table
                            :headers="headers"
                            :items="locations"
                            :items-per-page="10"
                            flat
                        ></v-data-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios' 
import moment from 'moment'

export default {
    data () {
      return {
        dialog: false,
        locationData: {
            location: '',
            updatedBy: '',
            remarks: ''
        },
        locationRules: [
            v => !!v || 'Location is required'
        ],
        updateByRules: [
            v => !!v || 'Name is required'
        ],
        headers: [
          {
            text: 'Location',
            align: 'start',
            sortable: false,
            value: 'location',
          },
          { text: 'Remarks', value: 'remarks' },
          { text: 'Updated By', value: 'updatedBy' },
          { text: 'Last Updated', value: 'updatedAt' }
        ],
        locations: [],
      }
    },

    created() {
        this.getLocation();
    },

    methods: {
        resetForm() {
            this.$refs.form.reset();
        },

        saveLocation() {
            this.createLocation();
        },

        async getLocation() {
            const apiURL = "https://wandb.prismatic-tech.com/api/location";
            await axios.get(apiURL).then(({ data }) => {
                if(data) {
                    this.locations = data.map(item => {
                        item.updatedAt = moment(item.updatedAt).format("ddd DD-MM-YYYY, hh:mm:ss a")
                        return item;
                    });
                }
            });
            console.log('RESHEHEHE', this.locations)
        },

        createLocation() {
            if(this.$refs.form.validate()) {
                const apiURL = "https://wandb.prismatic-tech.com/api/create-location";                
                
                if(this.locationData.remarks === '') {
                    this.locationData.remarks = "n/a";
                }

                axios.post(apiURL, this.locationData)
                .then(data => {
                    console.log(data);
                    this.locations.push(this.locationData);
                    this.resetForm();
                    this.locationData = {
                        location: '',
                        updatedBy: '',
                        remarks: ''
                    },
                    this.getLocation();
                })
                .catch(error => {
                    console.log(error);
                });
                
            }
        }
    }
  }
</script>