<template>
    <nav>
        <v-navigation-drawer app>  
          <div  style="outline:1px solid red; height:100vh" class="d-flex flex-column justify-space-between">
            <v-list>
              <v-list-item link :to="items[0].path">
                <v-list-item-icon>
                      <v-icon>{{ items[0].icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ items[0].title }}</v-list-item-title>
              </v-list-item> 

              <v-list-group :prepend-icon="items[1].icon">
                <template v-slot:activator>
                  <v-list-item-title>{{ items[1].title }}</v-list-item-title>
                </template>
                
                <v-list-item
                  v-for="(item, i) in manages"
                  :key="i"
                  link
                  :to="item.path"
                >
                    <!-- for spacing purpose only  -->
                  <v-list-item-icon></v-list-item-icon>

                  <v-list-item-title>{{ item.title }}</v-list-item-title>

                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-group>
            </v-list>

            <v-list>
              <v-list-item link @click="signOut">
                <v-list-item-icon>
                      <v-icon>{{ items[2].icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-title>{{ items[2].title }}</v-list-item-title>
              </v-list-item> 
            </v-list>
          </div>
        </v-navigation-drawer>        
    </nav>
</template>

<script>
  export default {
    data () {
      return {
        drawer: true,
        items: [
          { title: 'Dashboard', icon: 'mdi-monitor-dashboard', path: '/' },
          { title: 'Manages', icon: 'mdi-cog', path: '/about' },
          { title: 'Sign Out', icon: 'mdi-logout', path: '' },
        ],
        manages: [
          { title: 'Location', icon: 'mdi-map-marker', path: '/location' },
          { title: 'Devices', icon: 'mdi-devices', path: '/devices' },
        ],
        mini: true,
      }
    },

    methods: {
      signOut() {
        this.$keycloak.logout();
      },
    }
  }
</script>