<template>
    <div>
        <!-- <h2>Bill</h2> -->
        <v-row class="mt-10">
            <v-col cols="3" class="ma-0 pa-0">
                <v-card flat height=200 class="phase-1 pa-4 d-flex flex-column justify-space-between">
                    <h3>Phase 1</h3>
                    <h4 v-if="isNaN(billPhase1)">No Data</h4>
                    <h4 class="text-h4" v-else>RM{{billPhase1.toFixed(2)}}</h4>
                </v-card>
            </v-col>

            <v-col cols="3" class="ma-0 pa-0">
                <v-card flat height=200 class="phase-2 pa-4 d-flex flex-column justify-space-between">
                    <h3>Phase 2</h3>
                    <h4 v-if="isNaN(billPhase2)">No Data</h4>
                    <h4 class="text-h4" v-else>RM{{billPhase2.toFixed(2)}}</h4>
                </v-card>
            </v-col>
            <v-col cols="3" class="ma-0 pa-0">
                <v-card flat height=200 class="phase-3 pa-4 d-flex flex-column justify-space-between">
                    <h3>Phase 3</h3>
                    <h4 v-if="isNaN(billPhase3)">No Data</h4>
                    <h4 class="text-h4" v-else>RM{{billPhase3.toFixed(2)}}</h4>
                </v-card>
            </v-col>
            <v-col cols="3" class="ma-0 pa-0">
                <v-card flat height=200 class="overAll pa-4 d-flex flex-column justify-space-between">
                    <h3>Overall Bill</h3>
                    <h4 v-if="isNaN(totalOverallBill)">No Data</h4>
                    <h4 class="text-h4" v-else>RM{{totalOverallBill.toFixed(2)}}</h4>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default{
    props: {
        billPhase1: Number,
        billPhase2: Number,
        billPhase3: Number,
    },

    computed: {
        totalOverallBill() {
            const bills = [this.billPhase1, this.billPhase2, this.billPhase3]
            
            for(let i = 0; i < bills.length; i++) {
                if(isNaN(bills[i])) {
                    bills[i] = 0;
                }
            }

            const total = bills.reduce((sum, curr) => sum + curr, 0);

            return total;

        }
    }

    
}
</script>


<style scoped>
h3 {
    color: rgb(41, 41, 73);
}

h4 {
    color: rgb(74, 74, 115)
}

.phase-1, .phase-2, .phase-3 {
    border-radius: 0;
    border: 1px solid rgba(62, 73, 85, 0.208);
    border-left: none;
}

.overAll {
    border-radius: 0;
    border: 1px solid rgba(62, 73, 85, 0.208);
    border-right: none;
    border-left: none;
}
</style>