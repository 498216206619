<template>
  <div class="home">
    <v-container>
      <div class="mt-5 d-flex justify-space-between">
        <h1 class="h2">Dashboard</h1>
        <div class="d-flex">
            <v-select
            :items="locationList"
            value="locationList"
            label="Location"
            v-model="locationItem"
            :item-text="(locationList) => locationList.location"
            @change="updateLocation"
            dense
            flat
            outlined
            solo
            class="selLoc mr-2"  
            >
            </v-select>
            <date-picker format='DD-MMMM-YYYY' v-model='value1' @change="testDate" type='date' range placeholder='Select date range'></date-picker>
        </div>
      </div>

      <div class="mb-10">
        <RybBill :billPhase1="billPhase1" :billPhase2="billPhase2" :billPhase3="billPhase3" />
      </div>
      
      <div class="phase1Div">
        <h1 justify="center" align="center" class="mb-5 text-h5">Phase 1</h1>
        <LineChart :lineSeries="lineSeries" :key="lineSeries.length" />
        <!-- <LineChartTwo :lineSeries="lineSeries" :key="lineSeries.length" /> -->
      </div>

      <div class="mb-10">
        <InfoComp :kw_1="kw_1" :voltage_1="voltage_1" :current_1="current_1" :pf_1="pf_1" />
      </div>

      <div v-if="voltage_2.filter(val => val !== undefined).length !== 0">
        <div class="phase1Div">
          <h1 justify="center" align="center" class="mb-5 text-h5">Phase 2</h1>
          <LineChart :lineSeries="lineSeries2" :key="lineSeries2.length" />
        </div>

        <div class="mb-10">
          <InfoComp :kw_1="kw_2" :voltage_1="voltage_2" :current_1="current_2" :pf_1="pf_2" />
        </div>
      </div>
      
      <div v-if="voltage_3.filter(val => val !== undefined).length !== 0">
        <div class="phase1Div">
          <h1 justify="center" align="center" class="mb-5 text-h5">Phase 3</h1>
          <LineChart :lineSeries="lineSeries3" :key="lineSeries2.length" />
        </div>

        <div class="mb-10">
          <InfoComp :kw_1="kw_3" :voltage_1="voltage_3" :current_1="current_3" :pf_1="pf_3"/>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import LineChart from '@/components/LineChart.vue'
// import LineChartTwo from '@/components/LineChartTwo.vue'
import TitleCal from '@/components/TitleCalendar.vue'
import RybBill from '@/components/RybBill.vue'
import InfoComp from '@/components/InfoComp.vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: { LineChart, TitleCal, RybBill, DatePicker, InfoComp, },

  data() {
    return {
      value1: [],
      deviceLogs: [],
      resultChart: [],
      resultChart2: [],
      resultChart3: [],
      currentP1: [],
      currentP2: [],
      currentP3: [],
      tableHeaders: ['Timestamp', 'Serial #', 'Kilowatt(kW)', 'Voltage(v)', 'Event_id', 'Measurement'],
      loaded: false,
      lineSeries: [],
      lineSeries2: [],
      lineSeries3: [],
      labels: [],
      board: [],
      locationList: [],
      locationItem: [],
      kw_1: [],
      kw_2: [],
      kw_3: [],
      voltage_1: [],
      voltage_2: [],
      voltage_3: [],
      current_1: [],
      current_2: [],
      current_3: [],
      pf_1: [],
      pf_2: [],
      pf_3: [],
    }
  },
  
  created() {
    this.loadData();
    this.loadLocation();
    // this.intervalDataFetch();
  },
  
  methods: {
    intervalDataFetch() {
      setInterval(this.loadData, 60000)
    },

    testDate() {
      let date1 = moment(this.value1[0]).format('YYYY-MM-DD');
      let date2 = moment(this.value1[1]).format('YYYY-MM-DD');
      // let date1 = moment(this.value1[0]).toDate()
      // let date2 = moment(this.value1[1]).toDate()
  
      console.log(date1 === date2)
      if(date1 === date2) {
        // date2 = moment(date2).add(1, 'day').format('YYYY-MM-DD')
        date2 = moment(date2).endOf('days').format('YYYY-MM-DD')
      }
      
      console.log(moment(date1).toDate())
      console.log(moment(date2).endOf('days').toDate())
      this.loadDataByTimeRange(this.locationItem, date1, date2)
    },

    updateLocation() {
        this.loadDataByLocation();
    },

    groupByType(modData) {
      this.kw_1 = modData.map(kw => (kw?.fields?.cms_data?.p_phase1)).filter(kw => kw !== 0).sort();
      this.kw_2 = modData.map(kw => (kw?.fields?.cms_data?.p_phase2)).filter(kw => kw !== 0).sort();
      this.kw_3 = modData.map(kw => (kw?.fields?.cms_data?.p_phase3)).filter(kw => kw !== 0).sort();

      this.voltage_1 = modData.map(v => v?.fields?.cms_data?.v_phase1).filter(v => v !== 0);
      this.voltage_2 = modData.map(v => v?.fields?.cms_data?.v_phase2).filter(v => v !== 0).sort();
      this.voltage_3 = modData.map(v => v?.fields?.cms_data?.v_phase3).filter(v => v !== 0).sort();

      this.current_1 = modData.map(a => a?.fields?.cms_data?.i_phase1);
      this.current_2 = modData.map(a => a?.fields?.cms_data?.i_phase2);
      this.current_3 = modData.map(a => a?.fields?.cms_data?.i_phase3);

      this.pf_1 = modData.map(a => a?.fields?.cms_data?.pf1);
      this.pf_2 = modData.map(a => a?.fields?.cms_data?.pf2);
      this.pf_3 = modData.map(a => a?.fields?.cms_data?.pf3);
    },

   async loadData() {
      // const apiUrl = "http://localhost:3000/api/getAll";
      const apiUrl = "https://wandb.prismatic-tech.com/api/getAll";
      await axios.get(apiUrl).then(({ data }) => {
        const modData= data.map(item => {
          return item;
        })

        this.board = [...modData];
      
        this.groupByType(modData)
        

        this.resultChart = modData.map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.v_phase1
          }
        }).sort((a,b) => moment(a.x) - moment(b.x))

    

        this.resultChart2 = modData.map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.v_phase2
          }
        }).sort((a,b) => moment(a.x) - moment(b.x))

        this.resultChart3 = modData.map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.v_phase3
          }
        }).sort((a,b) => moment(a.x) - moment(b.x))

        this.currentP1 = modData.map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.i_phase1
          }
        }).sort((a,b) => moment(a.x) - moment(b.x))

        this.currentP2 = modData.map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.i_phase2
          }
        }).sort((a,b) => moment(a.x) - moment(b.x))

        this.currentP3 = modData.map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.i_phase3
          }
        }).sort((a,b) => moment(a.x) - moment(b.x))

        this.lineSeries = [
          {
            name: 'Voltage',
            data: this.resultChart
          },
          {
            name: 'Current',
            data: this.currentP1
          }
        ]

        this.lineSeries2 = [
          {
            name: 'Voltage',
            data: this.resultChart2
          },
          {
            name: 'Current',
            data: this.currentP2
          }
        ]

        this.lineSeries3 = [
          {
            name: 'Voltage',
            data: this.resultChart3
          },
          {
            name: 'Current',
            data: this.currentP3
          }
        ]

        
        this.deviceLogs = [...modData].map(item => {
          item.timestamp = moment(item.timestamp).format("ddd DD-MM-YYYY, hh:mm:ss a");
             return item
        }).reverse();
        
      })
    },

    async loadLocation() {
      const apiUrl = "https://wandb.prismatic-tech.com/api/location";
      await axios.get(apiUrl).then(({data}) => {
        const locList = data;

        this.locationList = [...locList]
        this.locationItem = this.locationList[0].location
      })
    },

    async loadDataByLocation() {
      const apiUrl = "https://wandb.prismatic-tech.com/api/getDataByLoc/" + this.locationItem;
      await axios.get(apiUrl).then(({ data }) => {
         const modData= data.map(item => {
          return item;
        })

        this.board = [...modData];
        console.log("BOARDLO", this.board)

        this.groupByType(modData);
        console.log('CHECK', this.kw_1)


        // this.resultChart = modData.map(item => {
        //   return {
        //     x: item.timestamp,
        //     y:item?.fields?.cms_data?.v_phase1
        //   }
        // })

       this.resultChart = modData.filter(val => val?.fields?.cms_data?.v_phase1 !== undefined).map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.v_phase1
          }
        }) 

        // this.currentP1 = modData.map(item => {
        //   return {
        //     x: item.timestamp,
        //     y:item?.fields?.cms_data?.i_phase1
        //   }
        // })
        this.currentP1 = modData.filter(val => val?.fields?.cms_data?.v_phase1 !== undefined).map(item => {
          return {
            x: item.timestamp,
            y: item?.fields?.cms_data?.i_phase1
          }
        }) 

        this.lineSeries = [
          {
            name: 'Voltage',
            data: this.resultChart
          },
          {
            name: 'Current',
            data: this.currentP1
          }
        ]

        console.log(this.lineSeries)
        
        this.deviceLogs = [...modData].map(item => {
          item.timestamp = moment(item.timestamp).format("ddd DD-MM-YYYY, hh:mm:ss a");
             return item
          }).reverse();
         })
  
    },
    async loadDataByTimeRange(loc, dt1, dt2) {
      const apiUrl = `https://wandb.prismatic-tech.com/api/getdata/${loc}/${dt1}/${dt2}`;
      await axios.get(apiUrl).then(({ data }) => {
         const modData= data.map(item => {
          return item;
        })

        this.board = [...modData];

        this.groupByType(modData)


        this.resultChart = modData.map(item => {
          return {
            x: item.timestamp,
            y:item?.fields?.cms_data?.v_phase1
          }
        })

        this.currentP1 = modData.map(item => {
          return {
            x: item.timestamp,
            y:item?.fields?.cms_data?.i_phase1
          }
        })

        this.lineSeries = [
          {
            name: 'Voltage',
            data: this.resultChart
          },
          {
            nama: 'Current',
            data: this.currentP1
          }
        ]

        this.deviceLogs = [...modData].map(item => {
          item.timestamp = moment(item.timestamp).format("ddd DD-MM-YYYY, hh:mm:ss a");
             return item
          }).reverse();
         })
    },
  },

  computed: {
     billPhase1() {
          var kw1 = this.board.map((kw) => kw?.fields?.cms_data?.p_phase1).filter(kw => kw !== 0).filter(kw => kw !== undefined).sort();
          var totalKw1 = Math.abs(kw1[kw1.length - 1] - kw1[0]);
          switch (totalKw1) {
            case totalKw1 > 900:
              return (200 * 0.218 + 100 * 0.334 + 300 * 0.516 + 300 * 0.546 + (totalKw1 - 900) * 0.571);
            case totalKw1 > 600:
              return (200 * 0.218 + 100 * 0.334 + 300 * 0.516 + (totalKw1 - 600) * 0.546);
            case totalKw1 > 300:
              return (200 * 0.218 + 100 * 0.334 + (totalKw1 - 300) * 0.516);
            case totalKw1 > 200:
              return (200 * 0.218 + (totalKw1 - 200) * 0.334);
            default:
              return (totalKw1 * 21.8) / 100;
          }       
    },

    billPhase2() {
        var kw2 = this.board.map((kw) => kw?.fields?.cms_data?.p_phase2).filter(kw => kw !== 0).filter(kw => kw !== undefined).sort();
        var totalKw2 = Math.abs(kw2[kw2.length - 1] - kw2[0])

        switch (totalKw2) {
          case totalKw2 > 900:
            return (200 * 0.218 + 100 * 0.334 + 300 * 0.516 + 300 * 0.546 + (totalKw2 - 900) * 0.571);
          case totalKw2 > 600:
            return (200 * 0.218 + 100 * 0.334 + 300 * 0.516 + (totalKw2 - 600) * 0.546);
          case totalKw2 > 300:
            return (200 * 0.218 + 100 * 0.334 + (totalKw2 - 300) * 0.516);
          case totalKw2 > 200:
            return (200 * 0.218 + (totalKw2 - 200) * 0.334);
          default:
            return (totalKw2 * 21.8) / 100;
        }
    },

    billPhase3() {
        var kw3 = this.board.map((kw) => kw?.fields?.cms_data?.p_phase3).filter(kw => kw !== 0).filter(kw => kw !== undefined).sort();
        var totalKw3 = Math.abs(kw3[kw3.length - 1] - kw3[0])

        switch (totalKw3) {
          case totalKw3 > 900:
            return (200 * 0.218 + 100 * 0.334 + 300 * 0.516 + 300 * 0.546 + (totalKw3 - 900) * 0.571);
          case totalKw3 > 600:
            return (200 * 0.218 + 100 * 0.334 + 300 * 0.516 + (totalKw3 - 600) * 0.546);
          case totalKw3 > 300:
            return (200 * 0.218 + 100 * 0.334 + (totalKw3 - 300) * 0.516);
          case totalKw3 > 200:
            return (200 * 0.218 + (totalKw3 - 200) * 0.334);
          default:
            return (totalKw3 * 21.8) / 100;
        }

  
    },
  }
}
</script>

<style>
  .selLoc {
    width: 180px;
  } 
  
  .v-data-table {
    border: 1px solid rgb(207, 207, 207);
  }

  .phase1Div {
    margin-top: 5rem;
  }

  .home .mx-input {
    height: 40px !important;
    border: 1px solid #aaaaaa !important;
  }
</style>
