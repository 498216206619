<template>
    <div class="d-flex justify-space-between">
        <h1 class="h2">Dashboard</h1>

        <div class="d-flex">
            <v-select
            class="selLoc mr-2"  
            :items="locationList"
            value="locationList"
            label="Location"
            v-model="locationItemChild"
            :item-text="(locationList) => locationList.location"
            @change="updateLocation"
            dense
            flat
            outlined
            solo
            >
            </v-select>
            <v-select
            class="helBtn"  
            :items="['Foo', 'Bar', 'Fizz', 'Buzz']"
            label="Last 12 Hours"
            dense
            flat
            outlined
            solo
            ></v-select>
        </div>
    </div>
</template>

<script>

export default{
    // props: {
    //     locationList: Array,
    //     locationItem: {}
    // },
    props:  ['locationList', 'locationItem', 'updateLocation'],

    data() {
        return {
            locationListChild: this.locationList,
            locationItemChild: this.locationItem,
        }
    },

    methods: {
    }
}
</script>


<style scoped>
.selLoc, .helBtn {
    width: 180px;
 }

.h2{
    color: rgb(41, 41, 73);
 }
</style>