<template>
  <div>
    <apexchart justify="center" height="400" align="center" type="line" :options="options" :series="lineSeries"></apexchart>
  </div>
</template>

<script>
import Vue from 'vue'
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

export default {
  props: {
    lineSeries: Array,
    labels: Array
  },

  data() {
    return {
       options: {
        markers: {
          size: 0,
          colors: undefined,
          strokeColors: '#fff',
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
      },
        chart: {
          line: 'line',
          id: 'chart',
          animations: {
            enabled: false
          }
        },
        noData: {
          text: 'No Data',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: -45,
          style: {
            color: undefined,
            fontSize: '18px',
          }
        },
        stroke: {
          show: true,
          curve: 'smooth',
          colors: undefined,
          width: 2,
          dashArray: 0,      
        },
        xaxis: {
          type:"datetime",
          labels: {
            datetimeUTC: false,
            style: {
              fontSize: "12px",
              colors: "primary",
            },
          },
        },
        yaxis: [
          {
            labels: {
              formatter: (val) => {
                return val.toFixed(2) + "V";
              },
            },
            title: {
              text: "Voltage"
            }
          },
          {
            opposite: true,
            labels: {
              formatter: (val) => {
                return val.toFixed(2) + "A";
              },
            },
            title: {
              text: "Current",
            }
          }
        ],
         tooltip: {
          x: {
            format: "dd MMM yyyy hh:mm:ss tt",
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          position: "top",
          labels: {
            colors: "primary",
          },
        },
      },
      // series: [{
      //   name: 'Line Chart',
      //   data: this.lineSeries
      // }]
    }
  }
}
</script>
