<template>
  <v-app>
    <Sidebar />
    <v-main class="pb-5">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from '@/components/Sidebar'

export default {
  name: 'App',
  components: { Sidebar },
  data: () => ({
    //
  }),
};
</script>
