import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Keycloak from 'keycloak-js'

Vue.config.productionTip = false
//  new Vue({
//       router,
//       vuetify,
//       render: h => h(App)
// }).$mount('#app')

let initOptions = {
  // url: 'http://127.0.0.1:8080/', 
  // realm: 'keycloak-demo', 
  url: 'https://keycloak.prismatic-tech.com/auth/', 
  realm: 'Wan RYB System', 
  clientId: 'app-vue', 
  onLoad: 'login-required',
  // redirectUri: 'http://localhost:8080/'
  redirectUri: 'https://wan.prismatic-tech.com/'
}

let keycloak = new Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak;

keycloak.init({ onLoad: initOptions.onLoad }).then((auth) => {
  if (!auth) {
    window.location.reload();
  } else {
    Vue?.$log?.info("Authenticated");
    
    // keycloak.redirectUri = "http://localhost:8080/"
    keycloak.redirectUri = "https://wan.prismatic-tech.com/"
    
    new Vue({
      el: '#app',
      router,
      vuetify,
      render: h => h(App, { props: { keycloak: keycloak } })
    })

      // new Vue({
      // router,
      // vuetify,
      // render: h => h(App, { props: { keycloak: keycloak } })
      // }).$mount('#app')
  }


//Token Refresh
  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        Vue?.$log?.info('Token refreshed' + refreshed);
      } else {
        Vue.$log.warn('Token not refreshed, valid for '
          + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
      }
    }).catch(() => {
      Vue?.$log?.error('Failed to refresh token');
    });
  }, 6000)

}).catch(() => {
  Vue?.$log?.error("Authenticated Failed");
});