<template>
    <div>
        <!-- <h2>Bill</h2> -->
        <v-row class="mt-10">
            <v-col cols="2.5" class="ma-0 pa-0">
                <v-card flat height=300 class="phase-1 pa-4 d-flex flex-column">
                    <h3 justify="center" align="center" class="mb-10">Kilowatt (kW)</h3>
                    <h4 v-if="!kw_1.length" justify="center" align="center" class="mt-16">No Data</h4>
                    <div justify="center" align="center" v-else>
                        <h4>Recently</h4>
                        <p>{{ recentKw }}kW</p>
                        <h4>Query Period Total</h4>
                        <p>{{ queryPeriodTotal }}kW</p>
                    </div>
                </v-card>
            </v-col>

            <v-col cols="2.5" class="ma-0 pa-0">
                <v-card flat height=300 class="phase-2 pa-4 d-flex flex-column">
                    <h3 justify="center" align="center" class="mb-10">Voltage (V)</h3>
                    <h4 v-if="!voltage_1.length" justify="center" align="center" class="mt-16">No Data</h4>
                    <div justify="center" align="center" v-else>
                        <h4>Recently</h4>
                        <p>{{ recentVolt }}V</p>
                        <h4>Max Value</h4>
                        <p>{{ maxVolt }}V</p>
                        <h4>Min Value</h4>
                        <p>{{ minVolt }}V</p>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="2.5" class="ma-0 pa-0">
                <v-card flat height=300 class="phase-3 pa-4 d-flex flex-column">
                    <h3 justify="center" align="center" class="mb-10">Current (A)</h3>
                    <h4 v-if="!current_1.length" justify="center" align="center" class="mt-16">No Data</h4>
                    <div justify="center" align="center" v-else>
                        <h4>Recently</h4>
                        <p>{{ recentCurrent }}A</p>
                        <h4>Max Value</h4>
                        <p >{{ maxCurrent }}A</p>
                        <h4>Min Value</h4>
                        <p>{{ minCurrent }}A</p>
                    </div>
                </v-card>
            </v-col>
            <v-col cols="2.5" class="ma-0 pa-0">
                <v-card flat height=300 class="overAll pa-4 d-flex flex-column">
                    <h3 justify="center" align="center" class="mb-10">Power Factor (P<span style="font-size: 12px">F</span>) </h3>
                    <h4 v-if="!pf_1.length" justify="center" align="center" class="mt-16">No Data</h4>
                    <div justify="center" align="center" v-else>
                        <h4>Recently</h4>
                        <p>{{ recentPf }}</p>
                        <h4>Max Value</h4>
                        <p>{{ maxPf }}</p>
                        <h4>Min Value</h4>
                        <p>{{ minPf }}</p>
                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default{
    props: {
       kw_1: Array,
       voltage_1: Array,
       current_1: Array,
       pf_1: Array,
    },
    
    computed: {
        recentKw() {
            let recentKwVal = this.kw_1.filter(val => val !== undefined);
            return recentKwVal[recentKwVal.length-1];
        },

        queryPeriodTotal() {
            let kwVals = this.kw_1.filter(val => val !== undefined);
            return Math.abs((kwVals[kwVals.length - 1] - kwVals[0])).toFixed(2);
        },

        recentVolt() {
            let voltVals = this.voltage_1.filter(val => val !== undefined);
            return voltVals[voltVals.length - 1];
            // return this.voltage_1.slice(-1)[0];
        },

        maxVolt() {
            let voltVals = this.voltage_1.filter(val => val !== undefined);
            return Math.max(...voltVals);
        },

        minVolt() {
            let voltVals = this.voltage_1.filter(val => val !== undefined);
            return Math.min(...voltVals);
        },

        recentCurrent() {
            let currentVals = this.current_1.filter(val => val !== undefined)
            return currentVals[currentVals.length - 1].toFixed(2);
        },

        maxCurrent() {
            let currentVals = this.current_1.filter(val => val !== undefined)
            return Math.max(...currentVals).toFixed(2);
        },

        minCurrent() {
            let currentVals = this.current_1.filter(val => val !== undefined)
            return Math.min(...currentVals).toFixed(2);
        },

        recentPf() {
            let pfVals = this.pf_1.filter(val => val !== undefined)
            return pfVals[pfVals.length - 1].toFixed(2);
        },

        maxPf() {
            let pfVals = this.pf_1.filter(val => val !== undefined)
            return Math.max(...pfVals);
        },

        minPf() {
            let pfVals = this.pf_1.filter(val => val !== undefined)
            return Math.min(...pfVals);
        }
    }

    
}
</script>


<style scoped>
h3 {
    color: rgb(41, 41, 73);
}

h4 {
    color: rgb(74, 74, 115)
}

.phase-1, .phase-2, .phase-3 {
    border-radius: 0;
    border: 1px solid rgba(62, 73, 85, 0.208);
    border-left: none;
    border-top: none;
    border-bottom: none;
}

.sagSwell {
    border-radius: 0;
    border: 1px solid rgba(62, 73, 85, 0.208);
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: none;
}

.overAll {
    border-radius: 0;
    border: 1px solid rgba(62, 73, 85, 0.208);
    border-left: none;
    border-bottom: none;
    border-top: none;
    border-right: none;
}
</style>